.languages {
    &__list {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        position: relative;
    }
    .languages__box{
        margin-top: 5px;
    }

    .languages__box.en.hidden {
        display: none;
    }

    .languages__box.ru.hidden {
        display: none;
    }

    .languages__box.he.hidden {
        display: none;
    }

    .languages__link[data-lang="en"]::before {
        border-radius: 50%;
        background-image: url(../../images/en.png);
    }

    .languages__link {
        // margin-top: 5px;
    }

    .languages__link_en {
        // &.active{
        display: flex;
        // }
        // display: none;
        // display: flex;
        // margin-left: auto;
        // margin-top: 5px;
    }

    .language__lists {
        position: absolute;
        top: 30px;
        display: none;

        &.active {
            display: block;
        }
    }

    &__box.active {
        button {
            display: flex;
        }

        display: flex;
    }

    &__item:nth-child(2) {
        // position: absolute;
        // bottom: -35px;
    }

    &__link {
        box-shadow: none;
        padding: 0;
        background-color: transparent;
        display: flex;

        flex-direction: row-reverse;

        &:hover,
        &:focus,
        &:active {
            color: var(--dark-color);

        }

        &.show {
            display: flex;

        }

        span {
            order: 2;
        }

        display: none;
        font-size: 14px;
        line-height: 170%;
        text-transform: uppercase;
        color: var(--dark-color);
        align-items: center;

        &::before {
            order: 2;
            content: '';
            display: block;
            min-width: 23px;
            width: 23px;
            height: 23px;
            background: no-repeat url(../../images/langs.svg);
            margin-right: 6px;
        }

        &_ru {
            &[data-lang="ru"]::before {
                background-position-x: 100%;
            }
        }

        &_he {
            &[data-lang="he"]::before {
                background-position-x: 0%;
            }
        }

        &__item {
            position: relative;

            &:nth-child(2) {}
        }

        &_active {
            display: flex;
            flex-direction: row-reverse;
            position: relative;

            &::after {
                position: absolute;
                left: -25px;
                order: 1;
                content: '';
                display: block;
                min-width: 15px;
                width: 15px;
                height: 20px;
                background: no-repeat url(../../images/chevron-down.svg);
                margin-right: 6px;
            }

        }
    }
}

@media (max-width: 768.98px) {
    .mobile-menu__languages .languages__box.he {
        // display: none;   

    }

    .languages .languages__box.en.hidden,
    .languages .languages__box.he.hidden,
    .languages .languages__box.ru.hidden {
        display: flex;

        button {
            opacity: 1;

        }
    }

    .languages .languages__box.en,
    .languages .languages__box.he,
    .languages .languages__box.ru {
        display: flex;

        button {
            opacity: 0.4;

        }
    }

    .languages {
        .ru.active {
            opacity: 1;
        }


        .en.active {
            opacity: 1;
        }

        .languages__link.he.active {
            // .languages__link_he {
            //     display: none;
            // }

            opacity: 0;
        }

        .languages__link_he {
            // display: none;
        }

        .ru,
        // .he,
        .en {

            display: flex;
            opacity: 0.5;
        }
    }

    .languages__link_en {
        margin-left: 5px;
    }

    .languages__link {
        margin-left: 5px;
    }

    .languages .language__lists {
        position: relative;
        top: 0;
        margin-left: 5px;
        display: flex;

        ul {
            display: flex;

            // li:first-of-type {
            //     display: none;
            // }
        }
    }
}