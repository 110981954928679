.header {
    position: relative;
    &__preview {
        display: flex;
    }
    &__disabled-icon {
        position: fixed;
        right: 15px;
        top: 40%;
        width: 58px;
        height: 58px;
        background: center/cover no-repeat url(../../images/disabled-icon.svg);
    }
    position: relative;

    // &::after {
    //     content: '';
    //     display: block;
    //     width: 100%;
    //     height: 100%;
    //     background-image: url(../../images/trailer.svg);
    //     position: absolute;
    //     top: 5px;
    //     background-position: bottom;
    //     background-repeat: no-repeat;
    //     background-size: 100%;

    // }

    &__bg {
        content: "";
        z-index: -1;
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        // background-image: url(../../images/trrrailer.png);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }

    &__container {
        position: relative;
        z-index: 2;
        padding: 0 16px;
        // padding-bottom: 132px;
    }

    &__inner {
        position: relative;
        max-width: 1170px;
        width: 100%;
        margin: 0 auto;
    }

    &__wrapper {
        z-index: 99;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: 1170px;
        width: 100%;
        margin: 0 auto;
        padding: 25px 0 25px;
    }

    &__box {
        max-width: 760px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        // margin-right: 25px;
    }
.whatsapp-icon{
    position: fixed;
    bottom: 15px;
    right: 15px;
    z-index: 999;
    width: 90px;
    height: 90px;
    background-color: #fff;
    border-radius: 50%;
}
    &__logo {
        color: var(--dark-color);
        font-weight: 700;
        font-size: 40px;
        line-height: 48px;
        width: 257.84px;
        height: 100px;

        &:hover,
        &:active,
        &:focus {
            color: var(--dark-color);
        }
    }

    &__list {
        position: relative;
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: space-between;
        max-width: 60%;
        // max-width: 443px;
        // max-width: 63%;
        width: 100%;
        margin: 0 -10px;
    }

    &__link {
        white-space: nowrap;
        margin: 0 10px;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        text-transform: uppercase;
        color: var(--dark-color);
        cursor: pointer;

        &:hover,
        &:active,
        &:focus {
            color: var(--dark-color);
        }
    }
    .additional-menu {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;

        &::before {
            content: "";
            opacity: 1;
            margin-left: 4px;
            display: block;
            width: 15px;
            height: 15px;
            // bottom: 3px;
            background: center/cover no-repeat url(../../images/chevron-downs.svg);
        }
    }
    &__items {
        position: relative;
        max-width: 292px;
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    &__languages {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: 119px;
        width: 100%;

    }

    &__phone {
        // margin: 0 8px;
        min-width: 32px;
        height: 32px;
        background: center/contain no-repeat url(../../images/phone-icon.svg);
        border: none;
        cursor: pointer;
        outline: none;
    }

    &__mobile {
        display: none;
    }

    &__scroll {
        display: flex;
        justify-content: center;
        background: bottom no-repeat url(../../images/scroll.svg);
        width: 40px;
        height: 40px;
        margin: 0 auto;
    }
    ._close {
        position: relative;
        &::before {
            background: center/contain no-repeat url(../../images/close-btn.svg);
            opacity: 1;
        }
    }
    &__burger {
        background: no-repeat url(../../images/burger-menu.svg);
        width: 29px;
        height: 20px;
        position: relative;
        z-index: 0;
    }
}

@media (max-width: 1399.98px) {
   
    .header__wrapper,
    .header__inner {
        max-width: 100%;
    }
}

@media (max-width: 1199.98px) {
    .header__box {
        margin-right: 20px;
    }

    .header__logo {
        width: 212.84px;
        height: 64px;
    }
}

@media (max-width: 991.98px) {

    
    .header__logo {
        margin-right: 5px;
    }
   
       
    .header__list {
        margin: 0 -5px;
    }
    .header__link {
        margin: 0 5px;
    }
    .header-social {
        display: none;
    }
    .header__items {
        justify-content: flex-end;
    }
}
button.header__disabled-icon:nth-child(2) {
    display: none;
}
@media (max-width: 768px) {
    .header .header-social {
        display: flex;
    }
    .header__logo {
        width: 140.82px;
        height: 50px;
        margin: 0;
    }

    .header {
        &::after {
            height: 100%;
        }

        &::before {
            background-size: cover;
            background-position: center;
        }

        &__container {
            padding: 16px 14px;

            background-size: cover;
        }

        &__logo {
            font-size: 24px;
            line-height: 20px;
        }

        &__mobile {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        &__scroll,
        &__wrapper {
            display: none;
        }
    }
    button.header__disabled-icon:nth-child(2) {
        display: block;
        position: fixed;
        top: 15%;
        right: 15px;
    }
    html .disabled-menu {
        position: fixed;
    }
}

@media (max-width: 575.98px) {
    .header .whatsapp-icon{
       width: 55px;
       height: 55px;
       bottom: 90px;
    }
    button.header__disabled-icon:nth-child(2) {
        display: block;
        position: fixed;
        top: 25%;
        right: 15px;
    }
    .header {
        &::before {
            background-position: top;
        }

        &__container {
            padding: 14px 16px;
            // height: 195px;
        }
    }
}
