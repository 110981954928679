.description {

    &__container {
        padding-top: 56px;
        padding: 56px 15px 68px;
        max-width: 800px;
        width: 100%;
        margin: 0 auto;

    }

    &__text {
        margin-bottom: 29px;
    }

    &__button {
        margin: 0 auto;
    }
}

@media (max-width: 768px) {
    .description {
        &__container {
            padding: 41px 15px 25px;
            max-width: 558px;
            width: 100%;
        }

        &__button {
            padding: 14px 5px 10px;
        }

        &__text {
            margin-bottom: 40px;
        }
    }
}

@media (max-width: 575.98px) {
    .description {
        &__container {
            padding: 36px 15px 25px;
            max-width: 558px;
            width: 100%;
        }

        &__button {
            padding: 14px 5px 10px;
        }

        &__text {
            margin-bottom: 40px;
        }
    }

}