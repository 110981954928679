.button-transparent {
    display: block;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-transform: uppercase;
    text-align: center;
    background-color: transparent;
    color: var(--green-color);
    border: 2px solid var(--green-color);
    max-width: 322px;
    width: 100%;
    padding: 14px 5px 14px;
    cursor: pointer;

    &:hover {
        color: var(--green-color);
        background: rgba(0, 49, 139, 0.1);
    }
    &:focus,
    &:active {
        color: var(--green-color);
        background: rgba(0, 49, 139, 0.3);
    }
}