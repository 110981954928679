.aside {
  // .aside__wrapper

  &__wrapper {
  }

  // .aside__social

  &__social {
    border: 1px solid var(--border, #e2e4ec);
    padding: 24px 19.5px;
    background: var(--white-100, #fff);
    margin-bottom: 35px;
  }

  // .aside__row

  &__row {
    display: flex;
    margin: 0 -4.5px 0px;
    justify-content: center;
  }

  &__link {
    min-width: 32px;
    width: 32px;
    height: 32px;
    margin: 0 4.5px;

    &_facebook {
      background: url("../../images/logo_Fb.svg");
    }
    &_instagram {
      background: url(../../images/logo_inst.svg);
    }
    &_tiktok {
      background: url(../../images/logo_Tiktok.svg);
    }
    &_whatsapp {
      background: url(../../images/logo_Whp.svg);
    }
  }
  // .aside__text

  &__text {
    color: var(--black-60, rgba(33, 33, 33, 0.6));
    text-align: center;
    font-size: 16px;
    line-height: 20px;
    max-width: 164px;
    width: 100%;
    margin: 0 auto;
    padding-bottom: 16px;
  }

  // .aside__subscribe

  &__subscribe {
    padding-top: 16px;
    border-top: 1px solid rgba(171, 176, 195, 0.4);
    color: var(--black-80, rgba(33, 33, 33, 0.8));
    text-align: center;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 1.2px;
    text-transform: uppercase;
  }

  // .aside__trailer

  &__trailer {
    margin-bottom: 83px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding: 32px 46px;
    height: 367px;
  }

  // .aside__trailer-hit

  &__trailer-hit {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--white-100, #fff);
    text-align: center;
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
    span {
      padding: 2px 4px;
      display: block;
      border-radius: 2px;
      background: var(--red-400, #dc3636);
      width: min-content;
      color: var(--white-100, #fff);
      text-align: center;
      font-size: 14px;
      font-weight: 900;
      line-height: 16px;
    }
  }

  // .aside__atricle

  &__atricle {
  }
}
.atricle {
  // .atricle__title

  &__title {
    color: var(--black-60, rgba(33, 33, 33, 0.6));
    font-size: 24px;
    font-weight: 600;
    line-height: 110%;
    text-transform: capitalize;
    margin-bottom: 16px;
  }

  // .atricle__box

  &__box {
    align-items: center;
    display: flex;
    margin: 0 -8px;
    margin-bottom: 16px;
    border: 1px solid #e2e4ec;
    padding: 16px;
  }

  // .atricle__img

  &__img {
    margin: 0 8px;
    width: 56px;
    height: 56px;
    min-width: 56px;
    border-radius: 50%;
  }

  // .atricle__desc

  &__desc {
    margin: 0 8px;
  }

  // .atricle__date

  &__date {
    color: var(--black-60, rgba(33, 33, 33, 0.6));
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 8px;
  }

  // .atricle__text

  &__text {
    color: var(--black-80, rgba(33, 33, 33, 0.8));
    font-size: 16px;
    line-height: 16px;
  }
}
@media (max-width: 768px) {
  .aside__social{
    margin: 0;
  }
  .aside__atricle ,
  .aside__trailer {
      display: none;
  }}