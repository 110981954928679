.about {
    background: rgba(0, 49, 139, 0.2);
    padding: 72px 0 114px; 
    &__container {
        max-width: 674px;
        width: 100%;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        // padding-bottom: 68px;
    }

    &__title {
        margin-bottom: 16px;
    }

    &__desc {
        text-align: center;
        // margin-bottom: 32px;
    }
}

@media (max-width: 768px) {
    .about {
        &__container {
            max-width: 480px;
            padding-bottom: 37px;
            padding-top: 21px;
        }

        &__title {
            margin-bottom: 20px;
        }

        &__desc {
            margin-bottom: 28px;
        }
    }
}

@media (max-width: 575.98px) {
    .about {
        padding: 0;
        &__container {
            max-width: 100%;
            padding-bottom: 37px;
            padding-top: 25px;
        }

        &__title {
            margin-bottom: 16px;
        }

        &__desc {
            margin-bottom: 40px;
        }
    }
}