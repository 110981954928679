.page {
    &__container {
        padding: 0 16px;
    }

    &__wrapper {
        max-width: 1170px;
        width: 100%;
        margin: 0 auto;
    }
}

.trailer-page {
    .disabled-menu {
        position: absolute;
        top: 21%;
        bottom: auto;
    }
    .header::after {
        content: none;
    }

    .card {
        &:nth-child(4),
        &:nth-child(5),
        &:nth-child(6) {
            display: none;
        }
    }

    .header {
        position: relative;

        &:before {
            // background-image: url(../../../../images/trailer-img.png);
            background-size: cover;
        }

        &__wrapper {
            padding: 10px 0 410px;
        }

        &__scroll {
            display: none;
        }

        &__container {
            position: relative;

            &::after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                display: block;
                height: 125px;
                width: 100%;
                background: linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0) 140%);
                backdrop-filter: blur(1.5px);
                z-index: -1;
            }
        }
    }
}
@media (max-width: 1199px) {
    .trailer-page .header__container::after {
        height: 90px;
    }
}
@media (max-width: 768px) {
    .trailer-page .header__container::after {
        height: 78px;
    }

    .trailer-page {
        .header__container {
            height: 360px;
        }
    }
}

@media (max-width: 575.98px) {
    .trailer-page {
        .gallery__container {
            padding-bottom: 60px;
            margin-bottom: 15px;
        }
        .header::before {
            background-position-x: -3%;
            transform: scale(1.2);
            background-position-y: -29px;
        }

        .trailers__container {
            padding-top: 71px;
        }
    }
}
