.news-page {
  // .news-page__wrapper

  &__wrapper {
    padding: 32px 0 140px;
  }

  // .news-page__category
  .location-card.hide {
    display: none;
  }
  .location-card {
    margin: 0 15px 30px;
  }
  &__category {
    margin: 0 -28px;
    position: relative;
    padding-bottom: 24px;
    margin-bottom: 44px;
    &::after {
      content: "";
      display: block;
      width: 90%;
      height: 2px;
      background-color: rgba(39, 43, 58, 0.1);

      position: absolute;
      bottom: 0;
      left: 28px;
    }
    // border-bottom: 2px solid rgba(39, 43, 58, 0.1);
  }

  // .news-page__button

  &__button {
    margin: 0 28px;
    color: #a9aab0;
    font-size: 24px;
    font-weight: 600;
    line-height: 120%;
    background-color: transparent;
    &.active {
      color: var(--gray, #333);
    }
  }

  // .news-page__cards

  &__cards {
    display: flex;
    margin: 0 -15px;
    flex-wrap: wrap;
    align-items: stretch;
  }
  .location-card {
    height: auto;
  }
  .location-card__info {
    // height: auto;
  }
}
.location-page {
  // .location-page__breadcrumbs

  &__breadcrumbs {
  }
}

@media (max-width: 768px) {
  // .location-page__breadcrumbs
  .news-page__category {
    padding-bottom: 8px;
    flex-wrap: wrap;
    align-items: stretch;
  }
  .news-page .location-card {
    max-width: 45%;
    height: auto;
  }
}

@media (max-width: 575.98px) {
  .news-page__wrapper {
    padding: 24px 0 42px;
  }
  .news-page__category {
    margin-bottom: 42px;
  }
  .news-page__button {
    margin: 0 16px;
  }
  .news-page__category {
    margin: 0 -16px 32px;
    overflow-x: scroll;
    flex-wrap: nowrap;
    display: flex;
    &::after{
      left: 23px;
    }
  }
  .news-page__cards {
    justify-content: center;
  }
  .news-page .location-card {
    max-width: 370px;
    height: auto;
  }
}
