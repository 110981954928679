.agreement {
  .description__button {
    display: none;
  }
  .description__text {
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
  }
  &__container {
    max-width: 770px;
    margin: 0 auto;
    width: 100%;
    padding-bottom: 68px;
  }
  &__title {
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
    margin-bottom: 15px;
  }
  &__box {
    &:not(:last-of-type) {
      margin-bottom: 40px;
    }
  }
  &__desc {
    font-weight: 400;
    font-size: 20px;
    line-height: 25px;
  }
}

@media (max-width: 767px) {
  .agreement {
    &__container {
      max-width: 528px;
    }
    &__title {
      font-size: 16px;
      line-height: 20px;
    }
    &__desc {
      font-size: 16px;
      line-height: 20px;
    }
    .description__text {
      font-weight: 700;
      font-size: 20px;
      line-height: 25px;
    }
  }
}
@media (max-width: 575px){
  .agreement{
     .description__text{
      text-align: left;
      margin-bottom: 20px;
    }

  }
}