.location-card {
    max-width: 370px;
    width: 100%;
    color: var(--dark-color);
    text-align: start;
    height: 100%;

    &__container {
        border: 1px solid var(--green-color);
        height: 100%;

    }

    &__img {
        height: 235px;
        width: 100%;
        background-size: cover;
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;

        &_location {
            background-image: url(../../images/location-img.png);
        }
    }

    &__name {
        font-weight: 900;
        font-size: 16px;
        line-height: 20px;
        background: var(--white-color);
        padding: 10px 24px;
        text-transform: uppercase;
        text-align: start;
        // width: 138px;
    }

    &__info {
        height: 55%;
        display: flex;flex-direction: column;
        justify-content: space-between;
        padding: 24px 24px 22px;
    }

    &__title {
        font-weight: 600;
        font-size: 24px;
        line-height: 120%;
        margin-bottom: 16px;

    }

    &__desc {
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 30px;
    }

    &__link {
        font-weight: 600;
        font-size: 20px;
        color: var(--dark-color);
        display: flex;
        align-items: center;

        &:hover,
        &:active,
        &:focus {
            color: var(--dark-color);

        }

        &::after {
            content: '';
            display: block;
            width: 36px;
            height: 36px;
            border-radius: 50%;
            background-color: var(--green-color);
            background-image: url(../../images/arrow-states.svg);
            background-repeat: no-repeat;
            background-position: center;
            margin-left: 5px;
        }
    }
}

@media (max-width: 768px) {}
.lazy-background-image {
    background-image: none;
  }
  .lazy-background-image.loaded {
    transition: background-image 0.3s;
  } 
@media (max-width: 575.98px) {
    .location-card__title {
        font-weight: 600;
        font-size: 22px;
        line-height: 120%;
    }

    .location__container {
        padding: 28px 0 74px;
    }

    .location-card__desc {
        margin-bottom: 9px;
    }
}