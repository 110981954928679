.contact {
    &__container {
        background: #a90025;
        // background-image:  url(../../images/contact-bg.png) ;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        padding: 82px 16px 115px;
    }

    &__content {
        max-width: 592px;
        margin: 0 auto;
        width: 100%;
        // display: flex;
        align-items: baseline;
    }

    &__box {
        &::before {
            left: -44%;
            bottom: -64%;
            content: "";
            position: absolute;
            width: 673px;
            height: 263px;
            background: center/contain no-repeat url(../../images/bg-contact.15e4f373154ead11ec21.webp);
        }

        position: relative;
        // display: flex;
        // background: rgba(0, 0, 0, 0.4);
        // backdrop-filter: blur(2px);
        // justify-content: center;
        color: var(--white-color);
        max-width: 1170px;
        width: 100%;
        margin: 0 auto;
        // padding: 21px 16px 32px;.contact__box
    }

    &__phone {
        // top: -5px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-left: auto;
        position: relative;
        max-width: 1170px;
        width: 100%;
    }
    .contact__phone-box {
        width: 61%;
        display: flex;
        justify-content: space-between;
        margin-left: auto;
    }
    &__title {
        color: var(--white-color);
        font-weight: 900;
        font-size: 40px;
        line-height: 68px;
    }

    &__info {
        max-width: 569px;
        // width: 56%;
        // margin-right: 24px;
    }

    &__desc {
        font-weight: 400;
        font-size: 20px;
        line-height: 28px;
        text-align: center;
        margin-bottom: 64px;
        // margin-top: -14px;

        br {
            display: none;
        }
    }

    &__title {
        font-weight: 900;
        font-size: 48px;
        line-height: 68px;
        margin-bottom: 7px;

        text-align: center;
    }

    &__link {
        margin: 0 0 0px;
        font-weight: 600;
        font-size: 28.3333px;
        line-height: 41px;
        color: #ffffff;
        display: flex;
        align-items: center;
        color: var(--white-color);
        justify-content: center;

        &:hover,
        &:active,
        &:focus {
            color: var(--white-color);
        }

        &:before {
            content: "";
            display: block;
            opacity: 0.5;
            width: 42px;
            height: 42px;
            background: center/cover no-repeat url(../../images/phone-right.svg);
            margin-right: 8px;
        }

        // &::after {
        //     content: '';
        //     display: block;
        //     width: 32px;
        //     height: 32px;
        //     background: no-repeat url(../../images/phone-right.svg);
        //     margin-left: 16px;
        // }
    }
}
@media (max-width: 991px) {
    .contact__box::before {
        content: none;
    }
}
@media (max-width: 768px) {
    .contact .contact__phone-box {
        display: block;
    }
    .contact {
        &__container {
            padding: 52px 16px 46px;
        }

        &__box {
            display: flex;
            max-width: 672px;
            align-items: center;
            // padding: 20px 16px 26px;
            // display: block;
        }

        &__title {
            text-align: left;
            font-size: 20px;
            line-height: 100%;
            margin-bottom: 11px;
        }

        &__desc {
            text-align: left;
            font-size: 14px;
            line-height: 120%;
            margin-top: 0;
            margin-bottom: 0;
            margin-right: 15px;
        }

        &__link {
            &::after {
                width: 24px;
                height: 24px;
                background-size: cover;
            }

            &::before {
                margin-right: 13px;
                width: 20px;
                height: 20px;
                background-size: cover;
            }

            font-size: 16px;
            line-height: 32px;
            margin: 0 auto 4px;
        }

        &__phone {
            display: flex;
            flex-direction: column;
            justify-content: center;
            top: 0;
            max-width: 224px;
        }
    }
}

@media (max-width: 575.98px) {
    .contact .contact__phone-box{
        width: 100%;
    }
    .contact {
        &__container {
            padding: 0;
        }

        &__desc {
            br {
                display: block;
            }
        }

        &__box {
            flex-direction: column;
            justify-content: center;
            padding: 26px 24px 24px;
        }

        &__content {
            display: block;
        }

        &__title {
            font-size: 18px;
            margin-bottom: 8px;
        }

        &__desc {
            font-size: 16px;
            line-height: 120%;
            margin-bottom: 11px;
        }

        &__link {
            margin-bottom: 13px;
            margin-left: 0;
            margin-right: 0;
        }

        &__phone {
            justify-content: center;
            top: 0;
            margin: 12px 0 0;
            max-width: 224px;
        }
    }
}
