.trailers {
    &__container {
        padding-top: 58px;
        padding-bottom: 100px;
    }
    .reviews__button {
        display: none;
    }
    &__title {
        text-align: center;
        margin-bottom: 75px;
    }

    &__row {
        display: flex;
        // overflow-x: scroll;
        flex-wrap: wrap;
        margin: 0 -15px;
    }
}

@media (max-width: 1199px) {
    .trailers {
        &__container {
            padding-top: 58px;
            padding-bottom: 100px;
        }

        &__title {
            margin-bottom: 65px;
        }

        &__row {
            margin: 0 -16px;
            justify-content: center;
        }
    }
}

@media (max-width: 1199px){
    .trailers .card {
  max-width: 100%;
  margin: 0 16px 32px;
}
}
@media (max-width: 768px) {
    .trailers__buttons-row {
        display: flex;
        justify-content: space-between;
    }
    .trailers {
        .trailers__buttons-row {
            align-items: center;
        }
        .reviews__button {
            display: block;
            position: relative;
            margin: 0;
            top: 0;
            width: 40px;
            height: 40px;
            background: center/contain no-repeat url(../../images/Right-long.svg);
            &_left {
                transform: rotate(180deg);
            }
            &_right {
                transform: rotate(0deg);
            }
            // transform: scale(1.1);
        }
        &__container {
            padding-top: 43px;
            padding-bottom: 56px;
        }
    }
}

@media (max-width: 716px) {
    .trailers {
        &__container {
            padding-bottom: 72px;
        }

        &__title {
            margin-bottom: 31px;
        }

        &__row {
            flex-wrap: nowrap;
            justify-content: flex-start;
            overflow-x: scroll;
            width: 100%;
            margin: 0;
        }
    }
}
@media (max-width: 575px) {
    .trailers {
        &__container {
            padding-bottom: 40px;
        }
    }
}
