.docs {
  // but
  .description__button {
    display: none;
  }
  .description__text {
    text-align: center;
  }
  &__wrapper {
    max-width: 770px;
    width: 100%;
    margin: 0 auto;
    padding-bottom: 68px;
  }
  &__box {
    padding: 16px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &:not(:last-of-type) {
      margin-bottom: 40px;
    }
  }
  &__title {
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 30px;
    display: flex;
    align-items: flex-end;
    text-transform: uppercase;
  }
  &__download {
    width: 100px;
    display: flex;
    align-items: center;
  }
  &__icon {
    &_pdf {
      width: 45px;
      height: 56px;
      background: center/cover no-repeat url(../../../images/XMLID_2268_.svg);
      margin-right: 16px;
    }
    &_download {
      width: 32px;
      height: 32px;
      background: no-repeat url(../../../images/tray-arrow-down.svg);
    }
  }
}

.he {
  .docs__icon_pdf {
    margin: 0;
  }
  .docs__icon_download {
    margin-right: 16px;
  }
  .docs__download,
  .docs__box {
    flex-direction: row-reverse;
  }
}
@media (max-width: 767px) {
  .docs__wrapper {
    max-width: 528px;
  }
  .docs__box:not(:last-of-type) {
    margin-bottom: 8px;
  }
  .docs__title {
    font-size: 20px;
    line-height: 25px;
  }
}

@media (max-width: 575px) {
  .docs__box:not(:last-of-type) {
    margin-bottom: 32px;
  }
  .docs__box {
    padding: 0;
  }
  .docs__title {
    font-size: 16px;
    line-height: 20px;
  }
}
