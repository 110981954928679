.trailer-plan {
    &__container {
        display: flex;
        max-width: 876px;
        width: 100%;
        margin: 0 auto;
        padding-bottom: 140px;
    }

    &__img {
        max-width: 509px;
        height: 280px;
        width: 100%;
        background: center/contain no-repeat url(../../images/trailer-prew.png);
        position: relative;
        left: -23px;
        margin-right: 15px
    }

    &__info {
        padding-top: 40px;
        max-width: 355px;
        width: 100%;
        margin-left: -26px;

    }

    &__name {
        font-weight: 600;
        font-size: 28px;
        line-height: 34px;
        color: var(--dark-color);
        margin-bottom: 24px;
        text-transform: capitalize;
    }

    &__desc {
        margin-bottom: 32px;
    }

    &__box {
        max-width: 322px;
        width: 100%;
    }

    &__text {
        text-transform: capitalize;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 6px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.3);

        &:not(:first-of-type) {
            padding-top: 16px;
        }
    }

    &__button {
        margin-top: 48px;
    }
}


@media (max-width: 768px) {
    .trailer-plan__info {
        padding-top: 0;

    }

    .trailer-plan__desc {
        margin-bottom: 30px;
    }

    .trailer-plan__button {
        padding: 14px 10px;
        max-width: 252px;
    }


    .trailer-plan__container {
        padding-bottom: 88px;
    }
}

@media (max-width: 716px) {
    .trailer-plan__container {
        flex-wrap: wrap;
        align-items: center;
    }

    .trailer-plan__info,
    .trailer-plan__img {
        margin: 0 auto;
        left: 0;
    }

}

@media (max-width: 575.98px) {

    .trailer-plan__img {
        height: 200px;
        transform: scale(1.1);
    }

    .trailer-plan__container {
        padding-bottom: 69px;
    }

    .trailer-plan__name {
        font-size: 28px;
        line-height: 34px;
        margin-top: 47px;
    }
}