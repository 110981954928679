@import "./styles/mixins.scss";
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;600;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Secular+One&display=swap");

* {
    transition: all 0.3s ease;
}

html[data-increase="true"] {
    .header__inner {
        max-width: 1250px;
    }
    .header__wrapper {
        max-width: 100%;
    }
    * {
        font-size: 18px !important;
    }
}

html[data-color="white"] {
    .header__container,
    .header__inner {
        z-index: auto;
    }
    .description .title {
        border: 1px solid;
    }
    .header,
    .header__box,
    .header__wrapper,
    .header__bg {
        z-index: 9;
        background-color: #ffffff00 !important;
    }
    &:not(.header),
    &:not(.header__bg) {
        background-color: #ffffff00 !important;
    }
    * {
        color: black !important;
        background-color: #fff !important;
        border-color: black !important;
    }
}

html[data-color="black"] {
    .header__container,
    .header__inner {
        z-index: auto;
    }
    .description .title {
        border: 1px solid;
    }
    .header,
    .header__box,
    .header__wrapper,
    .header__bg {
        z-index: 9;
        background-color: #ffffff00 !important;
    }
    &:not(.header),
    &:not(.header__bg) {
        background-color: #ffffff00 !important;
    }
    * {
        color: #fff !important;
        background-color: black !important;
        border-color: #fff !important;
    }
}
html[data-color="yellow"] {
    .header__container,
    .header__inner {
        z-index: auto;
    }
    .description .title {
        border: 1px solid;
    }
    .header,
    .header__box,
    .header__wrapper,
    .header__bg {
        z-index: 9;
        background-color: #ffffff00 !important;
    }
    * {
        color: #000000 !important;
        background-color: #f7f3d6 !important;
        border-color: #000000 !important;
    }
}

html[data-img="hidden"] {
    .contact__link::before,
    .languages .languages__link::before,
    * {
        background-image: none !important;
    }
}
// html[data-img="inverse"]
html[data-img="hidden"] .contact__box::before {
    content: none;
}
html[data-img="inverse"] {
    .location-card__link,
    .location-card__name,
    .location-card__desc,
    .location-card__title {
        color: #ffffff !important;
    }
    .trailer-page {
        .header__bg {
            background-color: transparent !important;
            z-index: 1;
        }
    }
    .header__bg,
    .trailer-plan__img,
    .gallery-thumbs-small__item,
    .gallery-thumbs__item,
    .contact__link::before,
    .footer__item,
    .reviews__scroll,
    .reviews__avatar,
    .location-card__img,
    .contact__box::before,
    .header__logo,
    .header__phone,
    // .disabled-menu,
    .header-social__link,
    .header__disabled-icon,
    .preview-slider__box,
    .help-item__icon,
    .card__img {
        filter: invert(1);
    }
}
html .disabled-menu__color_white {
    background-color: #fff !important;
}
html .disabled-menu__color_black {
    background-color: #000000 !important;
}
html .disabled-menu__color_yellow {
    background-color: #f7f3d6 !important;
}

.App {
    font-family: "Source Sans Pro", sans-serif;
    /* max-width: 1440px; */
    width: 100%;
    margin: 0 auto;
}

.title-h2 {
    @include h2;
}

.title-h3 {
    @include h3;
}

.title-h4 {
    @include h4;
}

.desc-small {
    @include desc-small;
}

.desc {
    @include desc;
}
.page {
    &__container {
        padding: 0 16px;
    }

    &__wrapper {
        max-width: 1170px;
        width: 100%;
        margin: 0 auto;
    }
}

.he {
    .contact .contact__phone-box {
        flex-direction: row-reverse;
        width: 58%;
        margin-left: 0;
        margin-right: auto;
    }
    .footer .contact__link {
        justify-content: flex-start;
        &::before {
            margin-right: 0;
            margin-left: 8px;
        }
    }
    .news-page__category {
        flex-direction: row-reverse;
        display: flex;
        &::after {
            right: 28px;
            left: auto;
        }
    }
    .atricle__text,
    .atricle__date {
        text-align: right;
    }

    .location-page {
        .swiper-button-prev {
            right: auto;
            left: 0;
        }
        .swiper-button-next {
            right: auto;
            left: 50px;
        }
    }
    .atricle__box,
    .location-page__row,
    .location-page__breadcrumbs-row {
        display: flex;
        flex-direction: row-reverse;
    }
    .location-page__breadcrumbs {
        text-align: right;
    }
    .mobile-menu__info {
        .mobile-menu__button {
            margin-left: auto;
        }
        .header-social {
            margin-left: auto;
        }
        .mobile-menu__link {
            // display: flex;
            // flex-direction: column;
            justify-content: flex-end;
        }
    }
    .disabled-menu__container {
        flex-direction: row-reverse;
    }
    .header__disabled-icon {
        left: 15px;
        right: auto;
    }
    .reviews__desc {
        text-align: right;
    }
    .preview__container {
        flex-direction: row-reverse;
    }

    .preview__row {
        flex-direction: row-reverse;
    }

    .preview__box {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }

    .preview-slider {
        left: 0;
    }

    .popup__button {
        order: 1;
    }

    .popup__box:nth-child(1) {
        order: 2;
    }

    .popup__box:nth-child(2) {
        order: 3;
    }

    .popup__box:nth-child(3) {
        order: 4;
    }

    .help-item__icon {
        margin-left: 10px;
        margin-right: 0;
    }

    font-family: "Secular One", sans-serif;

    .popup__box {
        text-align: right;

        input {
            text-align: right;
            width: inherit;
        }
    }

    .languages__list {
        display: flex;
        flex-direction: column-reverse;
    }

    * {
        font-weight: 400;
    }

    .header__wrapper {
        flex-direction: row-reverse;
    }

    .card__text span:nth-child(2) {
        display: flex;
        flex-direction: row-reverse;
    }

    .header__list {
        width: 57%;
        max-width: 100%;
        flex-direction: row;
    }

    .header__languages,
    .header__items {
        flex-direction: row-reverse;
    }
    .additional__link {
        text-align: right;
    }
    .additional__button {
        margin-left: auto;
    }
    .header__box {
        // max-width: 891px;
        // margin: 0;
        flex-direction: row-reverse;
    }

    .header__items {
        margin-right: 15px;
    }

    .header__link {
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
    }

    .about__desc {
        line-height: 29px;
        margin-bottom: 44px;
    }

    .button-transparent,
    .button-green {
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
    }

    .contact__link {
        font-weight: 400;
    }

    .contact__info {
        max-width: 384px;
        margin: 0 auto;
    }

    .contact__box::before {
        left: auto;
        right: -26%;
    }

    .contact__desc {
        margin-top: -9px;
        line-height: 120%;
    }

    .desc-small {
        text-align: right;
    }

    .help-item__row {
        display: flex;
        flex-direction: row-reverse;
        margin-bottom: 18px;

        &::before {
            margin-left: 5px;
            margin-right: 0;
        }
    }

    .our-help__box {
        border: 2px solid #6e992e;
        padding: 35px 16px 55px;
    }

    .our-help__title {
        text-align: center;
        margin-bottom: 43px;
    }

    .card__img {
        justify-content: flex-end;
    }

    .card__text {
        flex-direction: row-reverse;
    }

    .contact__box {
        padding: 26px 0px 32px 0px;
    }

    .contact__content {
        max-width: 100%;
        justify-content: space-between;
        flex-direction: row-reverse;
    }

    .footer .header__logo {
        margin-left: auto;
    }

    .contact__link {
        flex-direction: row-reverse;
        justify-content: flex-start;
        &::before {
            // background: no-repeat url(./images/phone-left.svg);
            min-width: 32px;
            width: 32px;
            height: 32px;
            background-size: contain;
            margin-left: 8px;
            margin-right: 0;
            position: relative;
            top: -1px;
        }

        &::after {
            margin-left: 0;
            min-width: 24px;
            height: 24px;
            background: no-repeat url(./images/phone-right.svg);
            position: relative;
            left: 7px;
            background-size: contain;
        }
    }

    .options__title {
        margin-bottom: 40px;
    }

    .accordion-item .accordion-button {
        // flex-direction: row-reverse;
        justify-content: space-between;
        padding-top: 27px;
    }

    .accordion-item {
        padding-bottom: 24px;
    }

    .accordion-button::after {
        margin-right: 0;
        margin-left: 0;
    }

    .accordion-body {
        padding: 24px 0 34px;
        text-align: right;
        font-size: 16px;
        line-height: 24px;
    }

    .contact__phone {
        flex-direction: row-reverse;
        margin-right: auto;
        margin-left: 0;
        // max-width: 272px;
    }
    .contact__link {
        margin-right: 0;
    }
    .contact__button {
        margin-left: auto;
        margin-left: 0;
        max-width: 264px;
    }

    .location-card__img {
        justify-content: flex-end;
    }

    .location-card {
        text-align: right;
    }

    .location-card__name {
        text-align: right;
        padding: 10px 24px 10px 40px;
        width: max-content;
    }

    .location-card__desc {
        line-height: 22px;
        margin-bottom: 41px;
    }

    .location-card__link {
        flex-direction: row-reverse;

        &::after {
            transform: rotate(180deg);
            margin: 0 16px 0 0;
        }
    }

    .footer__copy-text {
        font-weight: 400;
        font-family: "Source Sans Pro", sans-serif;
    }

    .swiper .swiper-pagination-progressbar {
        margin-left: auto;
        transform: scale(-1, 1);
    }

    .swiper-button {
        right: auto;
        left: 0;
        // transform: scale(-1, 1);
    }

    .footer__flex {
        flex-direction: row-reverse;
    }

    .footer__contacts {
        max-width: 272px;
    }

    .footer__item {
        transform: scale(-1, 1);
    }

    .footer__copy,
    .footer__row,
    .footer__box {
        text-align: right;
        flex-direction: row-reverse;
    }
}

@media (max-width: 1399.98px) {
    .he {
        .header__disabled-icon {
            left: 15px;
            right: auto;
        }
        .header__box {
            max-width: 60%;
        }
        .header__items {
            max-width: 36%;
            width: 100%;
        }
    }
}

/* breakpoints */

@media (max-width: 1199.98px) {
    .he {
        .location-page__row {
            flex-direction: column-reverse;
        }
        .languages {
            position: relative;
            left: 15px;
        }
    }
}

@media (max-width: 768px) {
    .he .contact__link {
        justify-content: center;
    }
}



//

@media (max-width: 1399.98px) {
}

@media (max-width: 1199.98px) {
}

@media (max-width: 768px) {
    .title-h2 {
        font-weight: 900;
        font-size: 24px;
        line-height: 30px;
    }

    .title-h3 {
        font-size: 20px;
        line-height: 25px;
    }

    .desc {
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
    }
}

@media (max-width: 575.98px) {
    .title-h2 {
        font-size: 20px;
        line-height: 25px;
    }

    .title-h3 {
        font-size: 18px;
        line-height: 23px;
    }

    .title-h4 {
        font-weight: 700;
        font-size: 20px;
        line-height: 120%;
    }
}

@media (max-width: 1200px) {
    .he .header__list {
        width: 100%;
        max-width: 100%;
    }

    .he {
        .preview-slider {
            padding-right: 0;
            // padding-left: 15px;
        }
    }

    .he .header__link {
        font-size: 15px;
        line-height: 24px;
    }
}

@media (max-width: 768px) {
    .he {
        .header__items {
            width: auto;
        }

        .languages {
            left: 0;
        }

        .preview-slider {
            padding: 0;
        }

        .preview__container {
            flex-direction: column-reverse;
        }
    }

    .he {
        .about__container {
            max-width: 480px;
            padding-bottom: 37px;
            padding-top: 27px;
        }

        .button-green {
            font-size: 20px;
            line-height: 24px;
            padding: 12px;
        }

        .about {
            &__title {
                margin-bottom: 17px;
            }

            &__desc {
                font-size: 16px;
                line-height: 23px;
                margin-bottom: 65px;
            }
        }

        .our-help {
            &__box {
                padding: 25px 16px 17px;
            }

            .help-item__icon {
                align-items: center;
                font-size: 20px;
                line-height: 120%;
                margin-bottom: 12px;
            }

            .help-item {
                margin-bottom: 40px;
            }

            .help-item__icon::before {
                width: 22px;
                height: 22px;
                background-size: cover;
            }
        }

        .trailers__container {
            padding-top: 54px;
        }

        .contact__box {
            max-width: 672px;
            padding: 0;
            flex-direction: row-reverse;
            // display: block;
        }

        .contact__phone {
            display: flex;
            flex-direction: column;
            max-width: 100%;
        }

        .contact__content {
            flex-direction: row-reverse;
            align-items: center;
            justify-content: space-between;
            flex-direction: row-reverse;
        }

        .contact__title {
            margin-bottom: 14px;
            text-align: right;
        }

        .contact__desc {
            text-align: right;
            margin-top: 0px;
            width: 71%;
            margin-left: auto;
            margin-right: 0;
        }

        .contact__button {
            padding: 8px;
            margin-left: 0;
            max-width: 224px;
        }

        .contact__link {
            margin: 0 auto 4px;
            font-size: 16px;
            line-height: 32px;
            font-weight: 400;
        }

        .contact__link::after {
            min-width: 20px;
            width: 20px;
            height: 20px;
            background-size: cover;
        }

        .contact__link::before {
            min-width: 24px;
            height: 24px;
        }

        .contact__info {
            text-align: right;
        }

        .footer__text .footer__social {
            margin-left: auto;
        }

        .footer__contacts {
            max-width: 100%;
        }

        .footer__contacts .mobile__box,
        .footer__contacts .mobile {
            display: flex;
            flex-wrap: wrap;
        }

        .footer .contact__link {
            width: 100%;
        }

        .footer__button {
            margin-left: auto;
        }

        .footer__contacts .mobile__box {
            margin-top: 14px;
        }

        .footer .contact__link {
            justify-content: flex-start;
            position: relative;
            left: 11px;
            margin-top: 15px;
        }

        .footer__copy {
            text-align: left;
            flex-direction: row-reverse;
        }

        .footer__contacts .footer__name {
            margin-right: 13px;
            margin-top: 20px;
        }
    }
}

@media (max-width: 575.98px) {
    .he {
        .about__title {
            font-size: 24px;
            line-height: 150%;
        }

        .about__container {
            padding-top: 25px;
            // margin-top: -8px;/
        }

        .about__desc {
            margin-bottom: 40px;
        }

        .our-help__title {
            width: 100%;
            margin: 0 auto 30px;
            font-size: 20px;
            line-height: 29px;
        }

        .our-help .help-item {
            margin-bottom: 37px;
        }

        .trailers__container {
            padding-top: 41px;
        }

        .trailers__title {
            margin-bottom: 25px;
        }

        .contact__desc {
            font-weight: 400;
            font-size: 14px;
            line-height: 120%;
            width: 100%;
            margin-bottom: 26px;
        }

        .contact__link {
            margin: 0 auto 14px;
        }

        .location-card__desc {
            line-height: 22px;
            margin-bottom: 21px;
        }

        .footer__button {
            right: 0;
        }

        .footer__contacts .footer__name {
            margin-right: 0;
        }

        .footer__imgs-mobile {
            margin-top: 23px;
        }

        .footer .contact__link {
            font-weight: 600;
            font-size: 19px;
            line-height: 32px;
            margin-top: -14px;

            &::before {
                transform: scale(1.2);
                margin-right: 15px;
            }
        }

        .footer__contacts .mobile__box {
            margin-left: auto;
        }

        .footer__contacts .mobile {
            padding-top: 66px;
        }

        .location__container {
            padding: 34px 0 74px;
        }

        .accordion-item {
            padding: 25px 0 26px;
        }

        .accordion-item .accordion-button {
            font-weight: 400;
            font-size: 24px;
            line-height: 120%;
            padding-top: 0;
        }

        .options__title {
            margin-bottom: 6px;
        }

        .accordion-body {
            padding: 21px 0 1px;
        }

        .title-h2 {
            font-weight: 400;
            font-size: 24px;
            line-height: 150%;
        }

        .contact__title {
            font-size: 20px;
            line-height: 100%;
        }

        .contact__box {
            padding: 19px 16px 26px;
            flex-direction: column;
        }

        .contact__phone {
            margin-left: auto;
        }
    }
}
@media (max-width: 575.98px) {
    .he .contact .contact__phone-box {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }
    .he .contact__button {
        margin-left: auto;
    }
    .he .contact__link {
        justify-content: flex-start;
    }
    .he {
        .location-page .swiper-button-next {
            right: 0;
            left: auto;
        }
    }
}   