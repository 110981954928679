.footer {

    background: #333;
    padding: 0 16px;
    color: var(--white-color);

    &__container {
        max-width: 1170px;
        width: 100%;
        margin: 0 auto;
        padding: 63px 0 28px;
    }

    &__box {
        display: flex;
        justify-content: space-between;
        margin: 0 -5px;
    }

    &__info {
        margin: 0 5px;
    }

    &__row {
        display: flex;
        max-width: 772px;
        // margin: 0 -5px;
        width: 100%;
        justify-content: space-between;
    }

    &__text {
        max-width: 354px;
        width: 100%;

        .footer__social {
            display: none;
        }
    }

    &__logo {
        font-weight: 700;
        font-size: 40px;
        line-height: 42px;
        color: var(--white-color);
        margin-bottom: 7px;
        display: block;

        &:active,
        &:focus,
        &:hover {
            color: var(--white-color);

        }
    }

    &__desc {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
    }

    &__imgs {
        max-width: 354px;
        width: 100%;

        &-mobile {
            display: none;
        }
    }

    &__name {
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        text-transform: uppercase;
    }

    &__flex {
        display: flex;
        flex-wrap: wrap;
        margin: 26px -7px 0;
    }

    &__item {
        margin: 0 7px 16px;
        border-radius: 3px;
        min-width: 78px;
        width: 78px;
        height: 64px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

        &_1 {
            background-image: url(../../images/footer-img-1.png);
        }

        &_2 {
            background-image: url(../../images/footer-img-2.png);
        }

        &_3 {
            background-image: url(../../images/footer-img-3.png);
        }

        &_4 {
            background-image: url(../../images/footer-img-4.png);
        }

        &_5 {
            background-image: url(../../images/footer-img-5.png);
        }

        &_6 {
            background-image: url(../../images/footer-img-6.png);
        }

        &_7 {
            background-image: url(../../images/footer-img-7.png);
        }

        &_8 {
            background-image: url(../../images/footer-img-1.png);
        }
    }


    &__contacts {
        max-width: 270px;
        width: 100%;

        .mobile {
            display: none;

        }
    }

    .contact__link {
justify-content: flex-start;
        margin: 20px 0 22px;
    }

    &__button {
        color: var(--white-color);
        border-color: var(--white-color);

        &:hover,
        &:active,
        &:focus {
            color: var(--white-color);
            background-color: transparent;
            border-color: var(--white-color);
        }
    }

    &__copy {
        padding-top: 27px;
        border-top: 1px solid #444444;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 33px -5px 0;
    }

    &__copy-text {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        opacity: 0.5;
        margin: 0 5px;
    }

    &__social {
        max-width: 193px;
        margin: 0 5px;
        width: auto;
        display: flex;
        justify-content: space-between;
    }

    &__link {
        width: 24px;
        height: 24px;
        margin: 0 4px;
        background-image: url(../../images/social.svg);

        &_whatsapp {
            background-image: url(../../images/wats.svg);
            background-repeat: no-repeat;
            background-position: center;
        }

        &_instagram {
            background-position-x: 32%;
        }

        &_twitter {
            background-position-x: 64%;
        }

        &_youtube {
            background-position-x: 100%;
        }
    }

    .mobile {
        display: none;
    }
}

@media (max-width: 991px) {
    .footer__box {
        flex-wrap: wrap;
    }
}

@media (max-width: 768px) {
    .footer {
        padding: 37px 16px 11px;

        &__name {
            margin-left: auto;
        }

        .contact__link {
            font-weight: 600;
            font-size: 22px;
            line-height: 32px;

            &:after {
                width: 34px;
                height: 34px;
                background-size: cover;
                // background: no-repeat url(http://localhost:3000/static/media/phone-left.8565d6073b0ae1345c15.svg);
            }

            &::before {
                width: 24px;
                height: 24px;
                // background: no-repeat url(http://localhost:3000/static/media/phone-right.55474850f64a771ca870.svg);
                margin-right: 7px;
            }
        }

        &__button {
            max-width: 270px;
            top: -11px;
            position: relative;
        }

        &__text {
            .footer__social {
                display: flex;
                margin-top: 32px;
                margin-left: 0;
            }
        }

        &__social {
            display: none;
        }

        &__container {
            padding: 0;
        }

        &__info {
            width: 50%;
        }

        &__logo {
            margin-bottom: 32px;
        }

        &__boxes {
            display: none;
        }

        .contact__link {
            margin: 0;
            width: 48%;
        }

        &__name {
            width: 48%;
            // margin-left: auto;
            margin-bottom: 10px;
        }

        &__contacts {
            width: 100%;
            max-width: 100%;

            .mobile {
                display: block;

                &__box {
                    display: flex;
                    justify-content: space-between;
                }
            }

            // margin-left: auto;
            // display: flex;
            // flex-wrap: wrap;
            // max-width: 100%;
            // width: 100%;
        }

        &__text {
            margin-bottom: 7px;
        }

        //         &__name {
        //             order: 1;
        //             width: 50%;
        //         }

        //         .contact__link {
        //             width: 51%;
        //             order: 3;
        //         }

        //         &__button {
        //             width: 55%;
        // max-width: 100%;
        //             order: 2;

    }

    .footer__copy {
        padding-top: 0;
        margin-top: 51px;
        padding-left: 16px;
        border: none;
    }

    .footer__copy-text {
        padding-top: 0;
        border: none;
        margin-top: 0;
    }
}

@media (max-width: 716.98px) {
    
    .footer {
        padding: 40px 16px 11px;
        .header__logo{
            max-width: 204px;
            width: 100%;
            height: 80px;
            margin-bottom: 32px;
        }
    }

    .footer__contacts .mobile__box {
        flex-wrap: wrap;

        * {
            width: 100%;
        }

    }

    .footer__info {
        width: 100%;
        max-width: 100%;
    }

    .footer__row {
        display: flex;
        flex-direction: column;
    }

    .footer__contacts .mobile {
        position: relative;
        padding-top: 89px;
    }

    .footer__name {
        width: 100%;
    }

    .footer__button {
        top: 0;
        position: absolute;
        top: 0;
        // margin-bottom: 40px;
    }
}

@media (max-width: 575.98px) {
    .footer__text {
        margin-bottom: 32px;
    }

    .footer__name {
        margin-bottom: 16px;
    }

    .footer__item {
        min-width: 71px;
        width: 71px;
        height: 64px;
        margin: 0 6px 16px;
    }

    .footer__flex {
        margin: 26px -7px 0;
    }

    .footer__copy {
        margin-top: 16px;
        padding-left: 0;
    }

    .footer {
        &__imgs {
            display: none;

            &-mobile {
                margin-top: 37px;
                display: block;
            }
        }
    }
}