.title {
    padding: 25px;
    background: #D9D6CF;
    backdrop-filter: blur(1.5px);
    text-align: center;
}   
@media (max-width: 768px) {
    .title{
            padding: 13px;
    }
}

@media (max-width: 575.98px) {}