.gallery {
    &__container {
        padding: 62px 0 164px;
    }

    &__title {
        text-align: center;
        margin-bottom: 72px;
    }

    &__thumb {
        max-width: 805px;
        width: 100%;
        margin: 0 auto;

        &_item {
        }
    }
}

.gallery-thumbs__item {
    width: 100%;
    height: 500px;
}

@media (max-width: 768px) {
    .gallery {
        &__container {
            padding: 62px 0 88px;
        }

        &__title {
            margin-bottom: 64px;
        }
    }

    .gallery-thumbs-small__item,
    .gallery-thumbs__item {
        width: 100%;
        background-size: cover;
        background-position: center;
    }
}

@media (max-width: 575.98px) {
    .gallery__container {
        padding: 47px 0 40px;
    }

    .gallery__title {
        margin-bottom: 33px;
    }
}

.gallery-thumbs {
    .swiper-button-next {
        width: 48px;
        height: 48px;
        background-color: rgba(254, 254, 254, 0.8);
        border-radius: 0px;
        right: 0;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url(../../images/arrow-right.svg);
        transition: all 0s;

        &::after {
            content: none;
        }
    }

    .swiper-button-prev {
        width: 48px;
        height: 48px;
        background-color: rgba(254, 254, 254, 0.8);
        border-radius: 0px;
        left: 0;
        background-repeat: no-repeat;
        background-position: center;
        transform: rotate(180deg);
        background-image: url(../../images/arrow-right.svg);
        transition: all 0s;

        &::after {
            content: none;
        }
    }

    .swiper-button-next.swiper-button-disabled,
    .swiper-button-prev.swiper-button-disabled {
        opacity: 1;
        background-image: url(../../images/arrow-disabled.svg);
    }

    .swiper-button-next.swiper-button-disabled {
        transform: rotate(180deg);
    }

    .swiper-button-prev.swiper-button-disabled {
        transform: rotate(0deg);
    }

    &__item {
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        height: 403px;

        &_main {
            background-image: url(../../images/gallery-thumbs-main.png);
        }

        &_1 {
            background-image: url(../../images/gallery-thumbs-2-2.png);
        }

        &_2 {
            background-image: url(../../images/gallery-thumbs-3.png);
        }

        &_3 {
            background-image: url(../../images/gallery-thumbs-4.png);
        }
    }
}

.gallery-thumbs-small {
    margin-top: 16px;

    &__item {
        background-position: center;
        cursor: pointer;
        height: 100px;
        width: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        height: 95px;

        &_main {
            background-image: url(../../images/gallery-thumbs-1.png);
        }

        &_1 {
            background-image: url(../../images/gallery-thumbs-2-2.png);
        }

        &_2 {
            background-image: url(../../images/gallery-thumbs-3.png);
        }

        &_3 {
            background-image: url(../../images/gallery-thumbs-4.png);
        }

        &_4 {
            background-image: url(../../images/gallery-thumbs-4.png);
        }
    }
}

@media (max-width: 575.98px) {
    .gallery .swiper {
        overflow: inherit;
    }
    .gallery .swiper-button-prev,
    .gallery .swiper-button-next {
        bottom: -25%;
        // position: relative;
        top: auto;
        z-index: 999999;
    }

    .gallery-thumbs-small {
        display: none;
    }

    .gallery-thumbs__item {
        height: 224px;
    }
}
