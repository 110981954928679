.location-page {
    .description__container {
        display: none;
    }
    &__breadcrumbs {
        color: rgba(33, 33, 33, 0.6);
        font-size: 16px;
        line-height: 16px;
        margin-bottom: 110px;
        &:hover {
            color: rgba(33, 33, 33, 1);
        }
    }
    &__main {
        max-width: 869px;
        width: 100%;
        margin: 0 15px;
    }
    &__row {
        display: flex;
        margin: 0 -15px;
    }
    &__aside {
        max-width: 270px;
        width: 100%;
        margin: 0 15px;
    }
    &__container {
        max-width: 1170px;
        width: 100%;
        margin: 0 auto;
        padding: 31px 0 124px;
    }

    .header::after {
        content: none;
    }
    .disabled-menu {
        position: absolute;
        top: 21%;
        bottom: auto;
    }
    &__desc {
        margin-top: 24px;
    }

    .gallery__container {
        padding-bottom: 140px;
    }

    &__button {
        margin: 41px 0 64px auto;
        color: var(--white, #fff);
        text-align: center;
        font-size: 16px;

        font-weight: 700;
        line-height: 24px;
        text-transform: uppercase;
    }

    .description__container {
        // padding: 56px 15px 26px;
        // max-width: 1364px;
        // width: 100%;
    }

    .header {
        position: relative;

        &:before {
            // background-image: url(../../../../images/location-background.png);
            background-size: cover;
        }

        &__wrapper {
            padding: 10px 0 410px;
        }

        &__scroll {
            display: none;
        }

        &__container {
            position: relative;

            &::after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                display: block;
                height: 125px;
                width: 100%;
                background: linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0) 140%);
                backdrop-filter: blur(1.5px);
                z-index: -1;
            }
        }
    }

    .description__text {
        width: 98%;
        margin: 0 auto 72px;
        text-align: center;
        // margin-bottom: 72px;
    }

    .description__button {
        display: none;
    }
}

.main-location {
    // .main-location__wrapper
    .swiper,
    .swiper-wrapper {
        position: inherit;
    }
    &__wrapper {
        position: relative;
    }

    // .main-location__titile

    &__titile {
        color: var(--gray, #333);
        font-size: 32px;
        font-weight: 900;
        line-height: 120%;
        text-transform: uppercase;
        margin-bottom: 62px;
    }

    // .main-location__date

    &__date {
        color: rgba(33, 33, 33, 0.6);
        font-size: 14px;
        line-height: 16px;
        margin-bottom: 32px;
    }

    // .main-location__desc

    &__desc {
        color: var(--gray, #333);
        font-size: 20px;
        margin-bottom: 28px;
    }

    // .main-location__swiper

    &__swiper {
    }
}
.swiper-location {
    // .swiper-location__slide
    .swiper-pagination-progressbar {
        margin-top: 41px;
        top: -8px;
    }
    .swiper-button-next,
    .swiper-button-prev {
        left: auto;
        top: auto;
        right: 0;
        bottom: 60px;
    }
    .swiper-pagination-progressbar {
        display: none !important;
    }
    .swiper-button-prev {
        &::after {
            content: none;
        }
        background: center/contain no-repeat url(../../../images/Right-long.svg);
        transform: rotate(180deg) ;
        right: 50px;
        left: auto;
        top: auto;
        bottom: 60px;
        width: 34px;
        height: 20px;
    }
    .swiper-button-next {
        &::after {
            content: none;
        }
        background: center/contain no-repeat url(../../../images/Right-long.svg);
        width: 34px;
        height: 20px;
    }

    &__slide {
        height: 436px;
        width: 100%;
    }
}
.location-page {
    // .location-page__button

    &__button {
    }

    // .location-page__main-desc

    &__main-desc {
        color: var(--gray, #333);
        font-size: 20px;
    }
}
@media (max-width: 1399px) {
    .location-page__container {
        padding: 31px 16px 124px;
    }
}
@media (max-width: 1199px) {
    .location-page__row {
        flex-direction: column-reverse;
        margin: 0;
        // padding:0  16px   0 0;
    }

    .location-page__aside {
        max-width: 100%;
        width: 100%;
        margin: 0 0;
    }
    .location-page__main-desc {
        margin-bottom: 50px;
    }
    .location-page__main {
        max-width: 100%;
        margin: 0;
    }
    .location-page .header__container::after {
        height: 90px;
    }
}

@media (max-width: 768px) {
    // .aside__atricle
    // .aside__trailer {
    //     display: none;
    // }
    .main-location__titile {
        margin-bottom: 48px;
        font-size: 24px;
        font-weight: 900;
        line-height: 120%;
    }
    .swiper-location__slide {
        height: 370px;
    }
    .swiper-location .swiper-pagination-progressbar {
        display: block;
        width: 80% !important;
        margin-top: 30px;
    }
    .main-location__desc {
        font-size: 16px;
        margin-bottom: 36px;
    }
    .main-location__date {
        margin-bottom: 20px;
    }
    .location-page__breadcrumbs {
        margin-bottom: 64px;
    }
    .location-page__container {
        padding: 24px 16px 88px;
    }
    .location-page__aside {
        max-width: 270px;
        margin: 0 auto;
    }
    .location-page .header__container::after {
        height: 78px;
    }

    .location-page .description__container {
        padding: 40px 15px 26px;
    }

    .location-page .description__text {
        width: 100%;
        margin: 0 auto 58px;
    }

    .location-page__button {
        margin: 40px 0 54px auto;
    }
    .location-page__main-desc {
        font-size: 20px;
    }

    .location-page .gallery__container {
        padding: 71px 0 88px;
        padding-bottom: 91px;
    }

    .location-page {
        .header__container {
            height: 366px;
        }
    }
}

@media (max-width: 575.98px) {
    .location-page__breadcrumbs {
        margin-bottom: 48px;
    }
    .main-location__titile {
        font-size: 20px;
        line-height: 120%;
        margin-bottom: 32px;
    }
    .main-location__date {
        font-size: 14px;
        line-height: 16px;
        margin-bottom: 16px;
    }
    .main-location__desc {
        font-size: 14px;
        margin-bottom: 32px;
    }
    .location-page .description__container {
        padding: 32px 15px 26px;
    }

    .location-page .description__text {
        text-align: left;
        margin: 0 auto 50px;
    }

    .location-page__button {
        margin: 24px 0 44px auto;
        padding: 14px 5px 10px;
    }
    .location-page__main-desc {
        font-size: 14px;

        margin-bottom: 48px;
    }
    .swiper-location__slide {
        height: 224px;
    }
    .location-page__container {
    }
    .swiper-location .swiper-button-prev {
        left: 0;
        // margin-bottom: -25px;
    }
    .swiper-location .swiper-pagination-progressbar {
        display: none;
    }
    .swiper-location {
        padding-bottom: 30px;
    }
    .location-page .gallery__container {
        padding: 48px 0 88px;
        padding-bottom: 69px;
    }

    .location-page {
        .description {
            .h3 {
                font-size: 20px;
                line-height: 25px;
            }

            .title {
                font-size: 20px;
                line-height: 25px;
                padding: 3px;
            }
        }

        .header::before {
            // background-position-x: -3%;
            transform: scale(1.2);
            background-position-y: -29px;
        }

        // .trailers__container {
        //     padding-top: 71px;
        // }
    }
}

@media (max-width: 352.98px) {
    .location-page {
        .header::before {
            background-position: 0;
            transform: scale(1);
            // background-image: url(../../../../images/bg-320.svg);
        }

        // .trailers__container {
        //     padding-top: 71px;
        // }
    }
}
