.button-green {
    display: block;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    text-transform: uppercase;
    text-align: center;
    background-color: #00318B;
    // background-color: var(--green-color);
    color: var(--white-color);
    max-width: 264px;
    width: 100%;
    cursor: pointer;
    padding: 14px 5px ;

    &:hover {
        background: #0046C7;
        color: var(--white-color);

    }
    &:active,
    &:focus{
        filter: drop-shadow(4px 6px 12px rgba(0, 70, 199, 0.5))
    }
}

@media (max-width: 768px) {
    .button-green {
        display: block;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        text-transform: uppercase;
        text-align: center;
        background-color: var(--green-color);
        color: var(--white-color);
        max-width: 264px;
        width: 100%;
        padding: 10px 5px ;
    }

}

@media (max-width: 575.98px) {}