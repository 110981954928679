@mixin h2 {
    font-weight: 900;
    font-size: 32px;
    line-height: 40px;
    color: var(--dark-color);
    text-transform: uppercase;
}

@mixin h3 {
    font-weight: 900;
    font-size: 24px;
    line-height: 30px;
    color: var(--dark-color);
    text-transform: uppercase;
}

@mixin h4 {
    font-weight: 700;
    font-size: 24px;
    line-height: 120%;
    color: var(--green-color);
}

@mixin label {
    font-weight: 900;
    font-size: 16px;
    line-height: 20px;
    color: var(--dark-color);
    text-transform: uppercase;
}

@mixin desc {
    font-weight: 400;
    font-size: 20px;
    line-height: 25px;
    color: var(--dark-color);
}

@mixin desc-small {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
}